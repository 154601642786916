import { Provider } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { AppConsts } from '../AppConsts';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';

export const providePM3DefaultMaterialOptions = (): Provider[] => [
    /* -------------------------------------------------------------------------- */
    /*  @ Tooltip
    /* -------------------------------------------------------------------------- */
    {
        provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
        useValue: {
            showDelay: 200,
            hideDelay: 0,
            touchendHideDelay: 0,
            disableTooltipInteractivity: true,
            positionAtOrigin: false,
        } as MatTooltipDefaultOptions,
    },

    /* -------------------------------------------------------------------------- */
    /*  @ Paginator
    /* -------------------------------------------------------------------------- */
    {
        provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
        useValue: {
            formFieldAppearance: 'fill',
            pageSize: AppConsts.grid.defaultPageSize,
            pageSizeOptions: AppConsts.grid.pageSizeOptions,
        } as MatPaginatorDefaultOptions,
    },
	{
		provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
		useValue: {
			duration: 3000,
			panelClass: ['mt-12', 'mr-3', 'w-auto', 'h-12'],
			verticalPosition: 'top',
			horizontalPosition: 'right',
		} as MatSnackBarConfig,
	},
];
