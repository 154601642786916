<!-- Header -->
<header mat-dialog-title>
	<h1>{{ data.header }}</h1>

	<button type="button" mat-icon-button mat-dialog-close>
		<fa-icon [icon]="['far', 'xmark']" class="text-emagine-grey-400 icon-size-8" />
	</button>
</header>
<mat-dialog-content>
	<div class="mb-6 whitespace-pre-line text-emagine-grey-600">
		{{ data?.message }}
	</div>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions class="flex justify-end bg-white">
	<button mat-flat-button class="invisible"></button>
	<button mat-flat-button color="primary" [mat-dialog-close]="true">
		{{ data?.buttonText }}
	</button>
</mat-dialog-actions>
