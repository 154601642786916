// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EEnvironmentName, IEnvironment } from './environment.entities';

export const environment: IEnvironment = {
	name: EEnvironmentName.Development,
	version: '1.1.2',
	production: false,
	dev: true,
	qa: false,
	apiUrl: 'https://pm3-dev-api.prodataconsult.com',
	sourcingUrl: 'https://web-sourcing-dev.prodataconsult.com',
	remoteGnomServiceBaseUrl: 'https://gnom-dev-api.prodataconsult.com',
	sharedAssets: 'https://web-sourcing-qa.prodataconsult.com/shared-assets',
	msalClientId: '6651cfbb-f282-4700-a1cf-f924304b0871',
	msalAuthorityUrl: 'https://login.microsoftonline.com/f5df7d60-53fa-47bc-b519-6f2681e92dfd/',
	msalInterceptorConfigUrl: 'api://9b7c3538-67bf-4e21-88ef-cb512296be90/access_as_user',
	gnomBackendScope: 'api://91948468-39fa-48f1-b873-b49a4fb53945/access_as_user',
	isSignalRLoggingEnabled: true,
	isChasePurchaseOrderEnabled: true,
	isEvaluationsEnabled: true,
	//proper way of hardcoding event groups ids, until new logic on BE
	notificationsEventGroups: {
		personal: 2,
		team: 1,
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
