import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { ThrottledClickDirective } from 'emg-fe-lib';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IconsModule } from '../shared/common/icons.module';
import { AuthService } from './auth.service';
import { PmValues } from './entities/login.entities';

@Component({
	standalone: true,
	selector: 'app-login',
	imports: [IconsModule, MatButtonModule, ThrottledClickDirective],
	templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
	@HostBinding('class') classes = 'w-screen h-screen bg-login-screen bg-emagine-grey-100 bg-no-repeat bg-contain flex';
	isIframe = false;
	loginDisplay = false;
	pmValues = PmValues;

	private readonly _destroying$ = new Subject();
	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private _msalService: MsalService,
		private _authService: AuthService,
		private msalBroadcastService: MsalBroadcastService,
	) { }

	ngOnInit(): void {
		this.setLoginDisplay();

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$),
			)
			.subscribe(() => {
				this.setLoginDisplay();
				this.checkAndSetActiveAccount();
			});
	}

	setLoginDisplay() {
		const loader = document.getElementById('appLoader') as HTMLElement;
		loader.classList.add('invisible');
		this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		this._authService.checkAndSetActiveAccount();
	}

	loginRedirect() {
		if (this.msalGuardConfig.authRequest) {
			this._msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
		} else {
			this._msalService.loginRedirect();
		}
	}

	loginPopup() {
		this._authService.loginWithMicrosoft();
	}

	logout(popup?: boolean) {
		if (popup) {
			this._msalService.logoutPopup({
				mainWindowRedirectUri: '/login',
			});
		} else {
			this._msalService.logoutRedirect();
		}
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
