import { enableProdMode, importProvidersFrom } from '@angular/core';

import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule, provideRouter, withInMemoryScrolling } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { provideAppInitializer } from './app/core/providers/initializer-providers';
import { provideMSAL } from './app/core/providers/msal-providers';
import { AuthRedirectGuard } from './app/login/auth-redirect.guard';
import { LoginGuard } from './app/login/login.guard';
import { environment } from './environments/environment';
import { RootComponent } from './root.component';
import { rootRoutes } from './root.routing';
import { GlobalHttpInterceptor } from './shared/service-proxies/global-http-interceptor';
import { providePM3DefaultMaterialOptions } from './shared/utils/pm3-material-providers';

import { EmgSnackBarService } from 'emg-fe-lib';
import { provideServiceProxies } from './app/core/providers/service-proxies-providers';
import { provideUrls } from './app/core/providers/urls-providers';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, {
  providers: [
    provideMSAL(),
    provideServiceProxies(),
    provideUrls(),
    provideAppInitializer(),
    providePM3DefaultMaterialOptions(),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
      withInterceptors([GlobalHttpInterceptor]),
    ),
    provideRouter(rootRoutes, withInMemoryScrolling()),
    importProvidersFrom(RouterModule.forRoot([...rootRoutes])),
    NgxSpinnerService,
    EmgSnackBarService,
    LoginGuard,
    AuthRedirectGuard,
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
