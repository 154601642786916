export const PmValues = [
    {
        id: 1,
        icon: 'dedicated-icon',
        name: 'Dedicated'
    },
    {
        id: 2,
        icon: 'responsible-icon',
        name: 'Responsible'
    },
    {
        id: 3,
        icon: 'genuine-icon',
        name: 'Genuine'
    },
    {
        id: 4,
        icon: 'confident-icon',
        name: 'Confident'
    }
];
