import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthRedirectGuard } from './app/login/auth-redirect.guard';
import { LoginComponent } from './app/login/login.component';
import { LoginGuard } from './app/login/login.guard';

export const rootRoutes: Route[] = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    {
        path: 'app',
        loadChildren: () => import('./app/app.routes').then((m) => m.APP_ROUTES),
        canActivate: [AuthRedirectGuard, MsalGuard],
    },
    {
        path: 'shared/clients',
        loadChildren: () => import('./app/client/clients-routing').then((m) => m.CLIENTS_ROUTES),
        canActivate: [AuthRedirectGuard, MsalGuard],
    },
    { path: '**', redirectTo: '/app' },
];
