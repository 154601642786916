import { APP_INITIALIZER, Provider } from '@angular/core';

import { filter, switchMap, tap } from 'rxjs';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

export const provideAppInitializer = (): Provider[] => [
	{
		provide: APP_INITIALIZER,
		deps: [MsalService, MsalBroadcastService],
		useFactory: (msalService: MsalService, msalBroadcastService: MsalBroadcastService) => () => {
			msalService
				.initialize()
				.pipe(
					switchMap(() => msalBroadcastService.inProgress$),
					filter((status: InteractionStatus) => status === InteractionStatus.None),
					tap(() => {
						const activeAccount = msalService.instance.getActiveAccount();
						const allAccounts = msalService.instance.getAllAccounts();

						if (!activeAccount && allAccounts.length > 0) {
							const [newActiveAccount] = allAccounts;

							msalService.instance.setActiveAccount(newActiveAccount);
						}
					}),
				)
				.subscribe();
		},
		multi: true,
	},
];
