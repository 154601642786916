import { Provider } from "@angular/core";

import { API_BASE_GNOM_EMPLOYEE } from "src/shared/notification-service-proxies/notification-service-proxies";
import { API_BASE_URL } from "src/shared/service-proxies/service-proxies";
import { GetRemoteGnomServiceBaseUrl, GetRemoteServiceBaseUrl } from "src/shared/utils/remote-urls-factory";

export const provideUrls = (): Provider[] => [{
    provide: API_BASE_URL,
    useFactory: GetRemoteServiceBaseUrl,
},
{
    provide: API_BASE_GNOM_EMPLOYEE,
    useFactory: GetRemoteGnomServiceBaseUrl,
},];
