import {
	HttpContextToken,
	HttpErrorResponse,
	HttpHandlerFn,
	HttpInterceptorFn,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/shared/common/errors/error-dialog.service';
import { MANUAL_ERROR_HANDLER_ENABLED } from './http-context-tokens';

export const BYPASS_LOG = new HttpContextToken(() => false);

export const GlobalHttpInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const router = inject(Router);
	const errorDialogService = inject(ErrorDialogService)
	const zone = inject(NgZone)
	const authService = inject(MsalService)

	const showDialog = (errorMessage: string, header: string) => {
		zone.run(() => errorDialogService.openDialog(errorMessage, header));
	}

	if (req.context.get(BYPASS_LOG)) {
		return next(req);
	}

	return next(req).pipe(
		catchError((error) => {
			if (req.context.get(MANUAL_ERROR_HANDLER_ENABLED) === true) {
				return throwError(() => error);
			}

			let handled = false;
			let message = '';
			let header = '';

			if (error instanceof HttpErrorResponse) {
				if (error.error instanceof ErrorEvent) {
					console.error('Error Event');
				} else {
					console.error(`Error Status : ${error.status} ${error.statusText}`);
					switch (error.status) {
						case 400:
							transformBlobToJson(error).then((response) => {
								if (response?.error?.message?.length > 0) {
									message = response?.error?.message;
								} else {
									for (let key of Object.keys(response?.errors)) {
										message += `${key}: ${response?.errors[key]?.join(', ')} \n`;
									}
									message = message?.length ? message : 'Invalid input';
								}
								header = 'Oops! Please check your request';
								handled = true;
								showDialog(message, header);
							});
							return throwError(() => error);
						case 401: //login
							header = 'Current user did not login to the application!';
							message = 'You will be redirected to login page.';
							handled = true;
							authService.logout();
							router.navigate(['/login']);
							break;
						case 403: //forbidden
							header = 'Access is forbidden';
							message = 'You will be redirected to login page.';
							router.navigateByUrl('/login');
							handled = true;
							break;
						case 500: //internal server error
							header = 'Internal server error';
							message = 'Status code: 500';
							showDialog(message, header);
							handled = true;
					}
				}
			} else {
				console.error('Other Errors ', error);
			}

			if (handled) {
				return of(error);
			} else {
				header = 'Error';
				message = 'Unexpected unhandled error';
				showDialog(message, header);
				return throwError(error);
			}
		}),
	);
}

function blobToText(blob: any): Observable<any> {
	return new Observable<any>((observer: any) => {
		if (!blob) {
			observer.next('');
			observer.complete();
		} else {
			const reader = new FileReader();
			reader.onload = (event) => {
				observer.next((<any>event.target).result);
				observer.complete();
			};
			reader.readAsText(blob);
		}
	});
}

const transformBlobToJson = (response: HttpErrorResponse): Promise<any> => {
	return new Promise((resolve) => {
		const responseBlob =
			response instanceof HttpResponse
				? response.body
				: (<any>response).error instanceof Blob
					? (<any>response).error
					: undefined;

		blobToText(responseBlob)
			.pipe(
				map((responseText) => {
					if (responseText !== null) {
						let responseObject;
						try {
							responseObject = JSON.parse(responseText);
						} catch (err) {
							responseObject = {};
						}
						return responseObject;
					}
					return null;
				}),
			)
			.subscribe((res) => {
				return resolve(res);
			});
	});
}