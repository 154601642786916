import { Provider } from "@angular/core";
import * as ApiServiceProxies from 'src/shared/service-proxies/service-proxies';

export const provideServiceProxies = (): Provider[] => [
    ApiServiceProxies.ClientsServiceProxy,
    ApiServiceProxies.EnumServiceProxy,
    ApiServiceProxies.HubSpotInstallServiceProxy,
    ApiServiceProxies.LookupServiceProxy,
    ApiServiceProxies.WorkflowServiceProxy,
    ApiServiceProxies.ClientPeriodServiceProxy,
    ApiServiceProxies.ClientDocumentsServiceProxy,
    ApiServiceProxies.ConsultantPeriodServiceProxy,
    ApiServiceProxies.MainOverviewServiceProxy,
    ApiServiceProxies.EmployeeServiceProxy,
    ApiServiceProxies.ProjectLineSyncServiceProxy,
    ApiServiceProxies.WorkflowDocumentServiceProxy,
    ApiServiceProxies.FileServiceProxy,
    ApiServiceProxies.AgreementServiceProxy,
    ApiServiceProxies.ClientAddressesServiceProxy,
    ApiServiceProxies.PurchaseOrderServiceProxy,
    ApiServiceProxies.FrameAgreementServiceProxy,
    ApiServiceProxies.HistoryServiceProxy,
    ApiServiceProxies.AgreementTemplateServiceProxy,
]