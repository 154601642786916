import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorDialogService {
	private _opened = false;
	private _dialog = inject(MatDialog);
	private _spinnerService = inject(NgxSpinnerService);

	openDialog(message: string, header: string, buttonText = 'Close'): void {
		if (!this._opened) {
			this._spinnerService.hide();
			this._opened = true;
			const dialogRef = this._dialog.open(ErrorDialogComponent, {
				data: { message, header, buttonText },
				height: 'auto',
				width: '500px',
				maxWidth: '100%',
				disableClose: true,
				hasBackdrop: true,
				panelClass: 'emg-default-window',
			});

			dialogRef.afterClosed().subscribe(() => {
				this._opened = false;
			});
		}
	}
}
