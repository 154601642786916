export interface IEnvironment {
	name: EnvironmentName;
	version: string;
	production: boolean;
	dev: boolean;
	qa: boolean;
	apiUrl: string;
	sourcingUrl: string;
	remoteGnomServiceBaseUrl: string;
	sharedAssets: string;
	msalClientId: string;
	msalAuthorityUrl: string;
	msalInterceptorConfigUrl: string;
	gnomBackendScope: string;
	isSignalRLoggingEnabled: boolean;
	isChasePurchaseOrderEnabled: boolean;
	isEvaluationsEnabled: boolean;
	//proper way of hardcoding event groups ids, until new logic on BE
	notificationsEventGroups: NotificationsEventGroups;
}

export enum EEnvironmentName {
	Development = 'dev',
	DockerTests = 'docker-tests',
	Production = 'prod',
	QA = 'qa',
	Local = 'local',
}

type EnvironmentName = `${EEnvironmentName}`;

type NotificationsEventGroups = {
	personal: number;
	team: number;
};
