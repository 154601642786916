import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from 'src/environments/environment';

enum EMsalScope {
    OpenId = 'openid',
    Profile = 'profile',
}
export const MSAL_SCOPES_GNOM = [EMsalScope.OpenId, EMsalScope.Profile, environment.gnomBackendScope];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalClientId,
            authority: environment.msalAuthorityUrl,
            redirectUri: '/',
            postLogoutRedirectUri: '/',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
            allowNativeBroker: false,
        },
    });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>([
        [environment.apiUrl, ['openid', 'profile', environment.msalInterceptorConfigUrl]],
        [environment.remoteGnomServiceBaseUrl, MSAL_SCOPES_GNOM]
    ]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openid', 'profile', environment.msalInterceptorConfigUrl],
        },
        loginFailedRoute: '/login',
    };
}

export const provideMSAL = (): Provider[] => [
    MsalService,
    MsalBroadcastService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true,
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory,
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
    },
    MsalGuard
]