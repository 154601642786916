<div class="flex h-full flex-1 flex-col items-start justify-center border-r border-solid border-gray-300 p-[4rem]">
	<img src="../../assets/images/login-logo.svg" class="absolute left-[52px] top-[47px] h-[35px] w-[210px]" />
	<div>
		<div class="mb-5 flex flex-row items-start justify-end">
			<img src="assets/images/login-title.svg" />
		</div>
		<p class="font-mono text-xl leading-4">Hope You have a good and productive day!</p>
		<button class="mt-6 bg-emagine-primary-500" mat-flat-button (throttledClick)="loginPopup()">
			<fa-icon [icon]="['fab', 'microsoft']" class="text-white" />
			<span class="ml-1.5 font-bold text-white">Sign in with Microsoft</span>
		</button>
	</div>
</div>
<div class="flex h-full flex-col items-center justify-center px-20">
	<div>
		<h2 class="mb-[30px]">Our values</h2>
		@for (value of pmValues; track value) {
			<div class="mb-8 flex items-center">
				<!-- TODO: ask designers to provide fontAwesome icons? -->
				<!-- <mat-icon class="h-12 w-12" [svgIcon]="value.icon"></mat-icon> -->
				<div class="mx-6 h-2 w-2 rounded-full bg-emagine-grey-500 opacity-50"></div>
				<span class="text-2xl">{{ value.name }}</span>
			</div>
		}
	</div>
</div>
