import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faAngleDown,
	faAngleUp,
	faArrowDown,
	faArrowDownArrowUp,
	faArrowDownToLine,
	faArrowLeft,
	faArrowRight,
	faArrowSquareRight,
	faArrowUp,
	faArrowUpRightFromSquare,
	faArrowUpToLine,
	faArrowsRetweet,
	faArrowsRotate,
	faAt,
	faBarsFilter,
	faBoxArchive,
	faCalendarCircleExclamation,
	faCalendarClock,
	faCalendarXmark,
	faChevronsDown,
	faChevronsUp,
	faCircleChevronRight,
	faCirclePlus,
	faCircleUser,
	faClose,
	faCodeMerge,
	faComment,
	faCookie,
	faEnvelopesBulk,
	faFile,
	faFileArrowDown,
	faFileArrowUp,
	faFileCircleExclamation,
	faFileCircleInfo,
	faFileCircleXmark,
	faFileDoc,
	faFilePdf,
	faFilePen,
	faFilePlus,
	faFileWord,
	faFileXmark,
	faFloppyDisk,
	faGears,
	faGlobe,
	faGripDotsVertical,
	faHand,
	faLink,
	faLinkSlash,
	faList,
	faLoader,
	faLock,
	faMemo,
	faMemoCircleInfo,
	faPaperPlane,
	faPaperPlaneTop,
	faPen,
	faPenSquare,
	faPenToSquare,
	faPipe,
	faPlus,
	faSignature,
	faSquareMinus,
	faTrashCanXmark,
	faTriangleExclamation,
	faWarning,
	faXmark,
	faAnglesRight as farAnglesRight,
	faArrowDown as farArrowDown,
	faArrowRightArrowLeft as farArrowRightArrowLeft,
	faArrowRightFromBracket as farArrowRightFromBracket,
	faArrowTurnDownLeft as farArrowTurnDownLeft,
	faArrowUpLeft as farArrowUpLeft,
	faArrowsRepeat as farArrowsRepeat,
	faBell as farBell,
	faBroom as farBroom,
	faBuilding as farBuilding,
	faBuildings as farBuildings,
	faCalendarLinesPen as farCalendarLinesPen,
	faCaretDown as farCaretDown,
	faChevronDown as farChevronDown,
	faChevronRight as farChevronRight,
	faChevronUp as farChevronUp,
	faCircleArrowLeft as farCircleArrowLeft,
	faCircleCheck as farCircleCheck,
	faCircleExclamation as farCircleExclamation,
	faCircleInfo as farCircleInfo,
	faCircleMinus as farCircleMinus,
	faCirclePlay as farCirclePlay,
	faCirclePlus as farCirclePlus,
	faCircleS as farCircleS,
	faCircleStop as farCircleStop,
	faCircleX as farCircleX,
	faCircleXmark as farCircleXmark,
	faClock as farClock,
	faClone as farClone,
	faCog as farCog,
	faCoins as farCoins,
	faCommentLines as farCommentLines,
	faCopy as farCopy,
	faCrown as farCrown,
	faDoNotEnter as farDoNotEnter,
	faDownload as farDownload,
	faEnvelope as farEnvelope,
	faEye as farEye,
	faEyeSlash as farEyeSlash,
	faFileLines as farFileLines,
	faGift as farGift,
	faHouse as farHouse,
	faLightbulbOn as farLightbulbOn,
	faList as farList,
	faLocationDot as farLocationDot,
	faMagnifyingGlass as farMagnifyingGlass,
	faMessageExclamation as farMessageExclamation,
	faMessageLines as farMessageLines,
	faMessageMinus as farMessageMinus,
	faMoneyCheckDollar as farMoneyCheckDollar,
	faPaperclipVertical as farPaperclipVertical,
	faPenToSquare as farPenToSquare,
	faPencil as farPencil,
	faPercent as farPercent,
	faPersonWalkingArrowRight as farPersonWalkingArrowRight,
	faPlay as farPlay,
	faPlus as farPlus,
	faRepeat as farRepeat,
	faRightFromBracket as farRightFromBracket,
	faRobot as farRobot,
	faRotateRight as farRotateRight,
	faShare as farShare,
	faSlidersUp as farSlidersUp,
	faSquareDollar as farSquareDollar,
	faStar as farStar,
	faTrash as farTrash,
	faTrashCan as farTrashCan,
	faUser as farUser,
	faUserMagnifyingGlass as farUserMagnifyingGlass,
	faUserMinus as farUserMinus,
	faUserPen as farUserPen,
	faUserPlus as farUserPlus,
	faUserSlash as farUserSlash,
	faUsers as farUsers
} from '@fortawesome/pro-regular-svg-icons';

import {
	faBell,
	faBellOn,
	faBuilding,
	faCalendarLines,
	faCircleInfo,
	faCommentLines,
	faEllipsis,
	faEllipsisH,
	faFileLines,
	faGear,
	faGripLines,
	faMagnifyingGlass,
	faRectangleList,
	faSearch,
	faSlidersUp,
	faStarSharp as faStarSharpLight,
	faTimes,
	faTriangleExclamation as faTriangleExclamationL,
	faUser,
	faUsers,
	faBars as falBars,
	faCalendarLines as falCalendarLines,
	faCalendarLinesPen as falCalendarLinesPen,
	faChartSimple as falChartSimple,
	faChartSimpleHorizontal as falChartSimpleHorizontal,
	faCheck as falCheck,
	faCircleArrowDown as falCircleArrowDown,
	faCircleArrowUp as falCircleArrowUp,
	faCircleChevronDown as falCircleChevronDown,
	faCircleChevronRight as falCircleChevronRight,
	faCircleChevronUp as falCircleChevronUp,
	faCirclePlay as falCirclePlay,
	faCirclePlus as falCirclePlus,
	faCog as falCog,
	faCreditCard as falCreditCard,
	faEarthAmericas as falEarthAmericas,
	faEllipsis as falEllipsis,
	faEye as falEye,
	faEyeSlash as falEyeSlash,
	faFileCircleCheck as falFileCircleCheck,
	faFileInvoiceDollar as falFileInvoiceDollar,
	faFileSignature as falFileSignature,
	faHouse as falHouse,
	faList as falList,
	faMoneyCheckDollar as falMoneyCheckDollar,
	faPencil as falPencil,
	faPlayCircle as falPlayCircle,
	faRobot as falRobot,
	faSquareList as falSquareList,
	faUserVneck as falUserVneck,
	faUsers as falUsers
} from '@fortawesome/pro-light-svg-icons';

import {
	faAngleRight,
	faBullhorn,
	faChevronDown,
	faCircleArrowRight,
	faCircleCheck,
	faCircleMinus,
	faCircleS,
	faCircleStar,
	faClone,
	faEnvelope,
	faEye,
	faEyeSlash,
	faRotate,
	faSkullCrossbones,
	faStar,
	faStarSharp,
	faArrowDownToDottedLine as fasArrowDownToDottedLine,
	faArrowRight as fasArrowRight,
	faArrowRotateLeft as fasArrowRotateLeft,
	faArrowUpFromBracket as fasArrowUpFromSquare,
	faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
	faBars as fasBars,
	faBarsFilter as fasBarsFilter,
	faBell as fasBell,
	faBellExclamation as fasBellExclamation,
	faBellPlus as fasBellPlus,
	faBuilding as fasBuilding,
	faCaretDown as fasCaretDown,
	faCheck as fasCheck,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasChevronRight,
	faChevronUp as fasChevronUp,
	faChevronsDown as fasChevronsDown,
	faCircle as fasCircle,
	faCircleCheck as fasCircleCheck,
	faCircleChevronRight as fasCircleChevronRight,
	faCircleExclamation as fasCircleExclamation,
	faCircleInfo as fasCircleInfo,
	faCircleMinus as fasCircleMinus,
	faCirclePlus as fasCirclePlus,
	faCircleX as fasCircleX,
	faCircleXmark as fasCircleXmark,
	faClock as fasClock,
	faCoins as fasCoins,
	faComment as fasComment,
	faDash as fasDash,
	faDoNotEnter as fasDoNotEnter,
	faEarthAmericas as fasEarthAmericas,
	faEllipsis as fasEllipsis,
	faEnvelopesBulk as fasEnvelopesBulk,
	faFileExcel as fasFileExcel,
	faFileImage as fasFileImage,
	faFileLines as fasFileLines,
	faFilePdf as fasFilePdf,
	faFileWord as fasFileWord,
	faImage as fasImage,
	faLink as fasLink,
	faLinkSlash as fasLinkSlash,
	faNotes as fasNotes,
	faPlus as fasPlus,
	faRefresh as fasRefresh,
	faSearch as fasSearch,
	faSignature as fasSignature,
	faStar as fasStar,
	faTriangle as fasTriangle,
	faTriangleExclamation as fasTriangleExclamation,
	faUserCrown as fasUserCrown,
	faUser as fasUsers,
	faXmark as fasXmark,
} from '@fortawesome/pro-solid-svg-icons';

import { faCircleUser as fatCircleUser, faEarthAmericas as fatEarthAmericas } from '@fortawesome/pro-thin-svg-icons';

import { faHubspot, faLinkedin, faMicrosoft } from '@fortawesome/free-brands-svg-icons';

@NgModule({
	imports: [FontAwesomeModule],
	exports: [FontAwesomeModule],
})
export class IconsModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			faFilePen,
			faGears,
			faFilePlus,
			faTrashCanXmark,
			faCalendarXmark,
			faStar,
			faHand,
			faPaperPlane,
			faSquareMinus,
			faAngleRight,
			fasEnvelopesBulk,
			faBellOn,
			faArrowDownArrowUp,
			faArrowDown,
			faPlus,
			faFileDoc,
			faMemoCircleInfo,
			faBoxArchive,
			faFile,
			faFileCircleExclamation,
			faCalendarCircleExclamation,
			faLinkSlash,
			faFileCircleXmark,
			faLink,
			faClone,
			faArrowSquareRight,
			faFileXmark,
			faSignature,
			faFilePdf,
			faFileWord,
			faPenSquare,
			faCircleArrowRight,
			faPaperPlaneTop,
			faArrowDownToLine,
			faFileArrowUp,
			faCalendarClock,
			farHouse,
			faPen,
			faArrowUpRightFromSquare,
			faXmark,
			faCodeMerge,
			faArrowUp,
			farCirclePlus,
			faAngleUp,
			faAngleDown,
			faGripDotsVertical,
			faLock,
			faCommentLines,
			faUser,
			farUser,
			faFileLines,
			fasCirclePlus,
			faClose,
			faBell,
			faSlidersUp,
			faXmark,
			faArrowUpToLine,
			faCircleCheck,
			farCircleCheck,
			faCircleMinus,
			faLoader,
			faMicrosoft as any,
			faHubspot,
			faEye,
			faEyeSlash,
			faRectangleList,
			faBuilding,
			faXmark,
			faSearch,
			fasSearch,
			faUsers,
			faEllipsis,
			faArrowRight,
			faArrowLeft,
			faArrowUpRightFromSquare,
			faPen,
			faMagnifyingGlass,
			farMagnifyingGlass,
			faEnvelopesBulk,
			faCircleInfo,
			farCircleInfo,
			faMemo,
			faPenToSquare,
			faChevronDown,
			farChevronRight,
			faCalendarLines,
			faBarsFilter,
			faGear,
			faGripLines,
			faCirclePlus,
			falCirclePlus,
			faStarSharp,
			faStarSharpLight,
			faList,
			farChevronDown,
			farChevronUp,
			faAngleDown,
			faEllipsisH,
			faTimes,
			faAt,
			fasPlus,
			fasBell,
			faCircleStar,
			faEnvelope,
			farEnvelope,
			farUsers,
			fasChevronLeft,
			farPencil,
			farShare,
			farCopy,
			farBell,
			faLinkedin as any,
			fasCheck,
			fasBuilding,
			farCoins,
			faTriangleExclamation,
			faPipe,
			fasCircleExclamation,
			fasArrowUpFromSquare,
			fasCoins,
			fasXmark,
			falCircleChevronRight,
			faArrowsRotate,
			faCircleUser,
			faComment,
			fasBars,
			fasBarsFilter,
			farPersonWalkingArrowRight,
			fasArrowRight,
			fasCircle,
			farArrowUpLeft,
			fasArrowDownToDottedLine,
			fasArrowRotateLeft,
			fasBellPlus,
			fasBellExclamation,
			farCommentLines,
			farArrowDown,
			fasFilePdf,
			fasFileWord,
			fasFileExcel,
			fasImage,
			farDownload,
			farRepeat,
			farBroom,
			farArrowRightFromBracket,
			fasDash,
			falCog,
			farEye,
			faGlobe,
			farTrash,
			falCheck,
			farList,
			falList,
			falUsers,
			farUsers,
			fasUsers,
			falHouse,
			falCircleArrowDown,
			falCircleArrowUp,
			farBroom,
			farArrowRightFromBracket,
			farCog,
			faCircleChevronRight,
			fasCircleChevronRight,
			faFileCircleInfo,
			faCookie,
			faWarning,
			farArrowTurnDownLeft,
			falCalendarLinesPen,
			farCalendarLinesPen,
			faChevronsUp,
			faChevronsDown,
			farCrown,
			falCircleChevronUp,
			falCircleChevronDown,
			faTriangleExclamationL,
			faFileArrowDown,
			farSlidersUp,
			farCircleArrowLeft,
			falRobot,
			farRobot,
			faRotate,
			fasCircleXmark,
			faSkullCrossbones,
			faCircleS,
			farCircleS,
			faArrowsRetweet,
			farCircleExclamation,
			farCircleX,
			faFloppyDisk,
			farGift,
			farLocationDot,
			farArrowsRepeat,
			farLightbulbOn,
			farPlay,
			farAnglesRight,
			farArrowRightArrowLeft,
			farCircleXmark,
			farRotateRight,
			falBars,
			falFileInvoiceDollar,
			falChartSimple,
			farRightFromBracket,
			falCirclePlay,
			falFileSignature,
			falChartSimpleHorizontal,
			falMoneyCheckDollar,
			fasClock,
			fasEllipsis,
			farCircleStop,
			fasRefresh,
			fasEarthAmericas,
			fatEarthAmericas,
			farMessageExclamation,
			farMessageMinus,
			farMessageLines,
			farPaperclipVertical,
			farPenToSquare,
			fasUserCrown,
			fasTriangle,
			falPencil,
			falEye,
			falEyeSlash,
			farFileLines,
			farUserMagnifyingGlass,
			fasCircleInfo,
			fasChevronUp,
			fasChevronsDown,
			falPlayCircle,
			farBuilding,
			falFileCircleCheck,
			fasFileImage,
			fasFileLines,
			farUserMinus,
			fatCircleUser,
			fasChevronRight,
			farStar,
			fasCircleCheck,
			fasCircleMinus,
			farEyeSlash,
			falEllipsis,
			falEarthAmericas,
			farChevronDown,
			farCircleMinus,
			farClock,
			farPlus,
			farPercent,
			fasSignature,
			fasArrowUpRightFromSquare,
			fasTriangleExclamation,
			farCaretDown,
			fasCaretDown,
			falCalendarLines,
			faBullhorn,
			fasNotes,
			farTrashCan,
			farCaretDown,
			farBuildings,
			farUserPen,
			farSquareDollar,
			farUserSlash,
			farUserPlus,
			farClone,
			falCreditCard,
			fasDoNotEnter,
			fasLink,
			fasLinkSlash,
			fasCircleX,
			farCirclePlay,
			falSquareList,
			fasStar,
			farDoNotEnter,
			fasComment,
			farMoneyCheckDollar,
			falUserVneck
		);
	}
}
