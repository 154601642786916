import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { IconsModule } from '../../icons.module';

@Component({
	standalone: true,
	selector: 'error-dialog',
	templateUrl: './error-dialog.component.html',
	imports: [
		IconsModule,
		MatDialogModule,
		MatButtonModule
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {
	readonly data = inject<{ message: string; header: string; buttonText?: string }>(MAT_DIALOG_DATA);
}
